import {
  GET_CONTACT,
  GET_CONTACT_ERROR,
  GET_CONTACT_SUCCESS,
} from "../constants";

export function getContact(session, resolve, reject) {
  return {
    type: GET_CONTACT,
    payload: session,
    resolve,
    reject,
  };
}

export const getContactSuccess = (data) => {
  return {
    type: GET_CONTACT_SUCCESS,
    payload: data,
  };
};

export const getContactError = (error) => {
  return {
    type: GET_CONTACT_ERROR,
    payload: error,
  };
};
