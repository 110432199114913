import React from "react";
import FooterClient from "../footerClient";
import HeaderClient from "../headerClient";
import ScrollButton from "../scrollButton";
import cn from "classnames";
import styles from "../pageClient/pageClient.css"
function PageClient({ children }) {
  return (
    <>
      <ScrollButton />
      <HeaderClient  classname={cn(styles["page-header"])}/>
      {children}
      <FooterClient />
    </>
  );
}

export default PageClient;
