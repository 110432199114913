import { GET_PRODUCT_SUCCESS } from "../constants";

const initialState = {
  product: [],
};

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_SUCCESS:
      console.log("action.payload.data", action.payload.data);
      const product = action.payload.data;

      return {
        ...state,
        product: [...product],
      };

    default:
      return state;
  }
}
