import axios from "axios";
import { BASE_URL } from "./config";

const instance = axios.create({
  baseURI: BASE_URL,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
    AccessControlAllowMethods: "GET, POST, PUT, DELETE, OPTIONS",
    "Cache-Control": "no-cache",
    "X-Requested-With": "XMLHttpRequest", //IMPORTANT!!
    // mode: "no-cors",
  },
  withCredentials: false,
  crossdomain: true,
});

instance.interceptors.request.use(
  (reqConfig) => {
    return reqConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response) {
      if (response && response.status === 200) {
      } else {
      }
    }
    return response;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default instance;
