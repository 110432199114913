import {
    SEND_MAIL,
    SEND_MAIL_ERROR,
    SEND_MAIL_SUCCESS,
  } from "../constants";
  
  export function sendMail(session, resolve, reject) {
    return {
      type: SEND_MAIL,
      payload: session,
      resolve,
      reject,
    };
  }
  
  export const sendMailSuccess = (data) => {
    return {
      type: SEND_MAIL_SUCCESS,
      payload: data,
    };
  };
  
  export const sendMailError = (error) => {
    return {
      type: SEND_MAIL_ERROR,
      payload: error,
    };
  };
  