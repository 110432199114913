import React, { useState } from "react";
import cn from "classnames";
import styles from './ScrollButton.module.css'
import { ArrowUp } from 'react-bootstrap-icons';

const ScrollButton = () =>{
  
    const [visible, setVisible] = useState(false)
    
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300){
        setVisible(true)
      } 
      else if (scrolled <= 300){
        setVisible(false)
      }
    };
    
    const scrollToTop = () =>{
      window.scrollTo({
        top: 0, 
        behavior: 'smooth'
      });
    };
    
    window.addEventListener('scroll', toggleVisible);
    
    return (
        <div onClick={scrollToTop} className={cn(styles['button'])} style={{opacity: visible ? '1' : '0'}}>
            <ArrowUp size={25}/>
        </div>
    );
  }
    
  export default ScrollButton;