import { GET_CONTACT } from "../constants";
import {
  getContactSuccess,
  setLoaderComplete,
  setLoaderStart,
  getContactError,
} from "../actions";
import { put, takeLatest, call } from "redux-saga/effects";
import { API_URL, BASE_URL } from "../axios/config";
import axios from "../axios";

async function getContactApi(data) {
  return axios.request({
    method: "post",
    url: BASE_URL + API_URL.CONTACT_URL,
    data: data,
  });
}

function* getContactAction(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(setLoaderStart());
    let response = yield call(getContactApi, payload);
    yield put(setLoaderComplete());
    if (response.status === 200) {
      console.log("response.data.data", response);
      yield put(getContactSuccess(response));
      if (resolve) resolve(response);
    } else {
      yield put(getContactError(response));
      if (reject) reject(response);
    }
  } catch (e) {
    console.log("error =======> ", e);
    yield put(setLoaderComplete());
    yield put(getContactError(e));
    if (reject) reject(e);
  }
}

export function* getContactWatcher() {
  yield takeLatest(GET_CONTACT, getContactAction);
}
