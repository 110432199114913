import { GET_CATEGORY } from "../constants";
import {
  getCategorySuccess,
  setLoaderComplete,
  setLoaderStart,
  getCategoryError,
} from "../actions";
import { put, takeLatest, call } from "redux-saga/effects";
import { API_URL, BASE_URL } from "../axios/config";
import axios from "../axios";

async function getCategoryApi(data) {
  return axios.request({
    method: "get",
    url: BASE_URL + API_URL.CATEGORY_URL,
    params: data,
  });
}

function* getCategoryAction(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(setLoaderStart());
    let response = yield call(getCategoryApi, payload);
    yield put(setLoaderComplete());
    if (response.status === 200) {
      console.log("response.data.data", response);
      yield put(getCategorySuccess(response));
      if (resolve) resolve(response);
    } else {
      yield put(getCategoryError(response));
      if (reject) reject(response);
    }
  } catch (e) {
    console.log("error =======> ", e);
    yield put(setLoaderComplete());
    yield put(getCategoryError(e));
    if (reject) reject(e);
  }
}

export function* getCategoryWatcher() {
  yield takeLatest(GET_CATEGORY, getCategoryAction);
}
