import { GET_PRODUCT } from "../constants";
import {
  getProductSuccess,
  setLoaderComplete,
  setLoaderStart,
  getProductError,
} from "../actions";
import { put, takeLatest, call } from "redux-saga/effects";
import { API_URL, BASE_URL } from "../axios/config";
import axios from "../axios";

async function getProductApi(data) {
  return axios.request({
    method: "post",
    url: BASE_URL + API_URL.PRODUCT_URL,
    data: data,
  });
}

function* getProductAction(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(setLoaderStart());
    let response = yield call(getProductApi, payload);
    yield put(setLoaderComplete());
    if (response.status === 200) {
      console.log("response.data.data", response);
      yield put(getProductSuccess(response));
      if (resolve) resolve(response);
    } else {
      yield put(getProductError(response));
      if (reject) reject(response);
    }
  } catch (e) {
    console.log("error =======> ", e);
    yield put(setLoaderComplete());
    yield put(getProductError(e));
    if (reject) reject(e);
  }
}

export function* getProductWatcher() {
  yield takeLatest(GET_PRODUCT, getProductAction);
}
