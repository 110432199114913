import { GET_CATEGORY_ERROR, GET_CATEGORY_SUCCESS } from "../constants";

const initialState = {
  category: [],
  banners: [],
  customerDetail: "",
  products: [],
};

export default function categoryReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_SUCCESS:
      console.log("action.payload.data", action.payload.data);

      return {
        ...state,
        category: [...action.payload.data.categories],
        banners: [...action.payload.data.banners],
        customerDetail: action.payload.data.customerDetail,
        products: [...action.payload.data.products],
      };

    case GET_CATEGORY_ERROR:
      console.log("action.payload.data", action.payload.response.statusText);

      return {
        ...state,
        invalid:
          action.payload.response.statusText === "Not Found" ? true : false,
      };

    default:
      return state;
  }
}
