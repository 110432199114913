import { generatePath } from "react-router";

export const homePattern = "/";
export const getHomeRoute = () => {
  return generatePath(homePattern);
};

export const contactPattern = "/contact";
export const getContactRoute = () => {
  return generatePath(contactPattern);
};

export const aboutPattern = "/about";
export const getAboutRoute = () => {
  return generatePath(aboutPattern);
};

export const categoryPattern = "/:name";
export const getCategoryRoute = (name) => {
  return generatePath(categoryPattern, { name });
};

export const layoutPattern = `/:name/layout`;
export const getLayoutRoute = (name) => {
  return generatePath(layoutPattern, {name});
};

export const tourPattern = `/:name/layout/tour/:id`;
export const getTourRoute = (id, name) => {
  return generatePath(tourPattern, { id, name });
};

export const categoryIDPattern = `/:name/:id`;
export const getCategoryIDRoute = (id, name) => {
  return generatePath(categoryIDPattern, { id, name });
};

export const categoryProductPattern = `/:name/:categoryId/product/:id/`;
export const getCategoryProductRoute = (id, categoryId, name) => {
  return generatePath(categoryProductPattern, { id, categoryId, name });
};

export const policyPattern = "/policy";
export const getPolicyRoute = () => {
  return generatePath(policyPattern);
};

export const errorPattern = "/error";
export const getErrorRoute = () => {
  return generatePath(errorPattern);
};
