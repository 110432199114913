import { GET_SIZE } from "../constants";
import {
  getSizeSuccess,
  setLoaderComplete,
  setLoaderStart,
  getSizeError,
} from "../actions";
import { put, takeLatest, call } from "redux-saga/effects";
import { API_URL, BASE_URL } from "../axios/config";
import axios from "../axios";

async function getSizeApi(data) {
  const path = window.location.pathname; // e.g., '/skymica/2'
  const parts = path.split("/");
  // Extract the relevant parts
  const category = parts[1];
  data.url = category;
  return axios.request({
    method: "get",
    url: BASE_URL + API_URL.SIZE_URL,
    params: data,
  });
}

function* getSizeAction(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(setLoaderStart());
    let response = yield call(getSizeApi, payload);
    yield put(setLoaderComplete());
    if (response.status === 200) {
      console.log("response.data.data", response);
      yield put(getSizeSuccess(response));
      if (resolve) resolve(response);
    } else {
      yield put(getSizeError(response));
      if (reject) reject(response);
    }
  } catch (e) {
    console.log("error =======> ", e);
    yield put(setLoaderComplete());
    yield put(getSizeError(e));
    if (reject) reject(e);
  }
}

export function* getSizeWatcher() {
  yield takeLatest(GET_SIZE, getSizeAction);
}
