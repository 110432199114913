import { GET_TEXTURE_SUCCESS } from "../constants";

const initialState = {
  texture: [],
};

export default function textureReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TEXTURE_SUCCESS:
      console.log("action.payload.data.texture", action.payload.data);
      const texture = action.payload.data;

      return {
        ...state,
        texture: [...texture],
      };

    default:
      return state;
  }
}
