import { GET_TESTIMONIAL } from "../constants";
import {
  getTestimonialSuccess,
  setLoaderComplete,
  setLoaderStart,
  getTestimonialError,
} from "../actions";
import { put, takeLatest, call } from "redux-saga/effects";
import { API_URL, BASE_URL } from "../axios/config";
import axios from "../axios";

async function getTestimonialApi(data) {
  return axios.request({
    method: "get",
    url: BASE_URL + API_URL.TESTIMONIAL_URL,
    data: data,
  });
}

function* getTestimonialAction(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(setLoaderStart());
    let response = yield call(getTestimonialApi, payload);
    yield put(setLoaderComplete());
    if (response.status === 200) {
      console.log("response.data.data", response);
      yield put(getTestimonialSuccess(response));
      if (resolve) resolve(response);
    } else {
      yield put(getTestimonialError(response));
      if (reject) reject(response);
    }
  } catch (e) {
    console.log("error =======> ", e);
    yield put(setLoaderComplete());
    yield put(getTestimonialError(e));
    if (reject) reject(e);
  }
}

export function* getTestimonialWatcher() {
  yield takeLatest(GET_TESTIMONIAL, getTestimonialAction);
}
