import React from "react";
import cn from "classnames";
import styles from "./loading.module.css";

function Loading() {
  return (
    <div className={cn(styles["body"])}>
      <div className={cn(styles["container"])}>
        <div className={cn(styles["loader"])}></div>
      </div>
    </div>
  );
}

export default Loading;
