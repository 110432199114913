import {
  GET_PRODUCT,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_SUCCESS,
} from "../constants";

export function getProduct(session, resolve, reject) {
  return {
    type: GET_PRODUCT,
    payload: session,
    resolve,
    reject,
  };
}

export const getProductSuccess = (data) => {
  return {
    type: GET_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const getProductError = (error) => {
  return {
    type: GET_PRODUCT_ERROR,
    payload: error,
  };
};
