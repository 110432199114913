import {
  GET_TEXTURE,
  GET_TEXTURE_ERROR,
  GET_TEXTURE_SUCCESS,
} from "../constants";

export function getTexture(session, resolve, reject) {
  return {
    type: GET_TEXTURE,
    payload: session,
    resolve,
    reject,
  };
}

export const getTextureSuccess = (data) => {
  return {
    type: GET_TEXTURE_SUCCESS,
    payload: data,
  };
};

export const getTextureError = (error) => {
  return {
    type: GET_TEXTURE_ERROR,
    payload: error,
  };
};
