import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "../header/header.module.css";
import { useWindowScrollPositions } from "../../utils/useWindowScrollPositions";
import useWindowDimensions from "../../utils/useWindowDimensions";
import { getCategoryRoute, getLayoutRoute, layoutPattern } from "../../routes";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";

function HeaderClient({ CustomerDetails }) {
  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const { width, height } = useWindowDimensions();
  const { scrollX, scrollY } = useWindowScrollPositions();

  useEffect(() => {
    if (width > 920) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [width]);

  return (
    <div
      className={cn(styles["navbar"])}
      style={
        scrollY > 50 && width > 920
          ? {
              borderBottom: " 1px solid var(--grey-three)",
              backgroundColor: "var(--white)",
              //padding: "10px 100px",
            }
          : scrollY > 100 && width < 919
          ? {
              borderBottom: " 1px solid var(--grey-three)",
              backgroundColor: "var(--white)",
            }
          : null
      }
      data-aos="fade-down"
      data-aos-delay="200"
    >
      <div className={cn(styles["row"])}>
        <Link to={getCategoryRoute(pathname.split("/")[1])}>
          {CustomerDetails && CustomerDetails.logo ? (
            <img
              src={CustomerDetails.logo}
              alt={CustomerDetails.logo}
              className={cn(styles["imgwidth"])}
            ></img>
          ) : (
            <button className={cn(styles["brand"])}>
              <b>
                {CustomerDetails &&
                  CustomerDetails.customerName.split(" ")[0].substring(0, 1) +
                    CustomerDetails.customerName.split(" ")[1].substring(0, 1)}
              </b>
            </button>
          )}
        </Link>
        {/* <button
          className={cn(styles["menu-btn"])}
          onClick={() => setShow(!show)}
        >
          <svg
            t="1583388234559"
            className={cn(styles["icon"])}
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="2279"
            width="30"
            height="30"
          >
            <path
              d="M128 213.34016l768 0q17.67424 0 30.16704 12.4928t12.4928 30.16704-12.4928 30.16704-30.16704 12.4928l-768 0q-17.67424 0-30.16704-12.4928t-12.4928-30.16704 12.4928-30.16704 30.16704-12.4928zM128 725.34016l768 0q17.67424 0 30.16704 12.4928t12.4928 30.16704-12.4928 30.16704-30.16704 12.4928l-768 0q-17.67424 0-30.16704-12.4928t-12.4928-30.16704 12.4928-30.16704 30.16704-12.4928zM128 469.34016l768 0q17.67424 0 30.16704 12.4928t12.4928 30.16704-12.4928 30.16704-30.16704 12.4928l-768 0q-17.67424 0-30.16704-12.4928t-12.4928-30.16704 12.4928-30.16704 30.16704-12.4928z"
              p-id="2280"
              fill="#40403F"
            ></path>
          </svg>
        </button> */}
      </div>
      <div className={cn(styles["clienttabs"])}>
        {/* {show ? (
          <> */}
        <Link to={getLayoutRoute(pathname.split("/")[1])}>
          <button
            className={cn(styles["header-visualizer-btn"])} // Add the class for the button styles
            style={pathname === layoutPattern ? "" : null}
          >
            Visualizer
          </button>
        </Link>
        {/* </>
        ) : null} */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    CustomerDetails: state.categoryReducer.customerDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderClient);
