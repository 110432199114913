import { GET_PRODUCT_DETAILS } from "../constants";
import {
  getProductDetailsSuccess,
  setLoaderComplete,
  setLoaderStart,
  getProductDetailsError,
} from "../actions";
import { put, takeLatest, call } from "redux-saga/effects";
import { API_URL, BASE_URL } from "../axios/config";
import axios from "../axios";

async function getProductDetailsApi(data) {
  return axios.request({
    method: "get",
    url: BASE_URL + API_URL.PRODUCT_DETAILS_BYCODE_URL,
    params: data,
  });
}

function* getProductDetailsAction(action) {
  let { payload, resolve, reject } = action;
  try {
    yield put(setLoaderStart());
    let response = yield call(getProductDetailsApi, payload);
    yield put(setLoaderComplete());
    if (response.status === 200) {
      console.log("response.data.data", response);
      yield put(getProductDetailsSuccess(response));
      if (resolve) resolve(response);
    } else {
      yield put(getProductDetailsError(response));
      if (reject) reject(response);
    }
  } catch (e) {
    console.log("error =======> ", e);
    yield put(setLoaderComplete());
    yield put(getProductDetailsError(e));
    if (reject) reject(e);
  }
}

export function* getProductDetailsWatcher() {
  yield takeLatest(GET_PRODUCT_DETAILS, getProductDetailsAction);
}
