import { GET_SIZE, GET_SIZE_ERROR, GET_SIZE_SUCCESS } from "../constants";

export function getSize(session, resolve, reject) {
  return {
    type: GET_SIZE,
    payload: session,
    resolve,
    reject,
  };
}

export const getSizeSuccess = (data) => {
  return {
    type: GET_SIZE_SUCCESS,
    payload: data,
  };
};

export const getSizeError = (error) => {
  return {
    type: GET_SIZE_ERROR,
    payload: error,
  };
};
