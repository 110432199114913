import { GET_SIZE_SUCCESS } from "../constants";

const initialState = {
  size: [],
};

export default function sizeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SIZE_SUCCESS:
      return {
        ...state,
        size: action.payload.data, // Ensure this matches your API response structure
      };
    default:
      return state;
  }
}
