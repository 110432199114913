import {
  GET_TESTIMONIAL,
  GET_TESTIMONIAL_ERROR,
  GET_TESTIMONIAL_SUCCESS,
} from "../constants";

export function getTestimonial(session, resolve, reject) {
  return {
    type: GET_TESTIMONIAL,
    payload: session,
    resolve,
    reject,
  };
}

export const getTestimonialSuccess = (data) => {
  return {
    type: GET_TESTIMONIAL_SUCCESS,
    payload: data,
  };
};

export const getTestimonialError = (error) => {
  return {
    type: GET_TESTIMONIAL_ERROR,
    payload: error,
  };
};
