import { GET_PRODUCT_DETAILS_SUCCESS } from "../constants";

const initialState = {
  productDetails: {},
};

export default function productDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_DETAILS_SUCCESS:
      console.log("action.payload.data", action.payload.data);

      return {
        ...state,
        productDetails: { ...action.payload.data },
      };

    default:
      return state;
  }
}
