import { GET_TESTIMONIAL_SUCCESS } from "../constants";

const initialState = {
  testimonial: [],
};

export default function testimonialReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TESTIMONIAL_SUCCESS:
      console.log("action.payload.data", action.payload.data);
      const testimonial = action.payload.data;

      return {
        ...state,
        testimonial: [...testimonial],
      };

    default:
      return state;
  }
}
