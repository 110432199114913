import { combineReducers } from "redux";
import loader from "./loader";
import categoryReducer from "./category";
import productReducer from "./product";
import sizeReducer from "./size";
import textureReducer from "./texture";
import productDetailsReducer from "./productDetails";
import testimonialReducer from "./testimonial";
import contactReducer from "./contact";
import mailReducer from "./mail";

const rootReducer = combineReducers({
  loader,
  categoryReducer,
  productReducer,
  sizeReducer,
  textureReducer,
  productDetailsReducer,
  testimonialReducer,
  contactReducer,
  mailReducer,
});

export default rootReducer;
