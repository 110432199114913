import React, { useEffect } from "react";
import { scrollWindow } from "../../utils/scrollWindow";
import Footer from "../footer";
import Header from "../header";
import ScrollButton from "../scrollButton";

function Page({ children }) {
  useEffect(() => {
    scrollWindow();
  }, [children]);
  return (
    <>
      <ScrollButton />
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default Page;
