import {
  GET_CATEGORY,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_SUCCESS,
} from "../constants";

export function getCategory(session, resolve, reject) {
  return {
    type: GET_CATEGORY,
    payload: session,
    resolve,
    reject,
  };
}

export const getCategorySuccess = (data) => {
  return {
    type: GET_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const getCategoryError = (error) => {
  return {
    type: GET_CATEGORY_ERROR,
    payload: error,
  };
};
