import { SEND_MAIL_SUCCESS } from "../constants";

const initialState = {};

export default function mailReducer(state = initialState, action) {
  switch (action.type) {
    case SEND_MAIL_SUCCESS:
      console.log("action.payload.data", action.payload.data);

      return {
        ...state,
      };

    default:
      return state;
  }
}
